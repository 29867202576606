.headerSection {
    position: absolute;
    top: 0;
    width: 100%;
}

.layoutSection {
    width: 100%;
}

/*  */

/* Default styles for larger screens */
.bodySection {
    width: 100%;
    height: auto;
    /* Set to auto to contain the content properly */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.contentsection {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentsection p {
    margin-top: 20%;
    font-family: "League Spartan";
    color: black;
    font-size: 20px;
    line-height: 35px;
}

.contentsection p strong {
    font-weight: bold;
    font-size: 30px;
}

.imageSection {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    margin-left: 100px;
    width: 500px;
    height: 400px;
}

.hireDevelopersButton {}

/* Styles for mobile screens */
@media screen and (max-width: 768px) {
    .bodySection {
        flex-direction: column-reverse;
        align-items: center;
        /* Center the items on the cross-axis */
    }

    .contentsection {
        width: 90%;
        margin: 0;
        /* Remove any default margins */
    }

    .imageSection {
        width: 90%;
        margin-top: 100px;
    }

    .contentsection p {
        margin-top: 10%;
        /* Reduce the top margin for mobile screens */
    }

    .image {
        margin-left: 0;
        width: 80%;
        /* Adjust the width to be less than the container for some padding */
        height: auto;
        /* Adjust the height to maintain the aspect ratio */
    }

    .hireDevelopersButton {
        display: flex;
        margin: 40;
        width: 200px;
        justify-content: space-between;
    }

    .hireDevelopersButton button {
        margin-bottom: 10px;
        /* Adds spacing between buttons */
    }
}

.image2 {
    margin-right: 100px;
    width: 500px;
    height: 400px;
}





.bodySectionFilter {
    display: flex;
    width: '100%';
    height: 100px;
    margin-top: 100px;
    margin-left: 100px;
    margin-right: 100px;
    height: 130vh;
}

.bodySectionFilter2 {
    display: flex;
    width: '100%';
    height: 100px;
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 100px;
    height: 100vh;
}

.bodySectionFilter3 {
    flex: 0.8;
    display: flex;
    width: '100%';
    margin-top: 150px;
    margin-left: 230px;
    margin-right: 100px;
    /* height: "100%"; */
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bodySectionFilter4 {
    margin-top: 150px;
    width: 230px;
}

.bodySectionFilter5 {
    display: flex;
    width: '50%';
    margin-top: 120px;
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 100px;
    padding: auto;
}

.body-container {
    display: flex;
    flex-direction: row;
}

.filterSection {
    flex: 0.3;
}

.listSecton {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 10px;
    padding-right: 1px;
    padding-left: 1px;
}

.listSecton2 {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 10px;
    padding-right: 1px;
    padding-left: 50px;
}

.swipeableList {
    width: 90%;
    height: 70px;
    margin-left: 100px;
}



.ratingSection {
    width: 240px;
    height: 200px;
    margin-top: 15%;
    /* background-color: blue; */
}

.discriptionSection {
    width: 100%;
    height: 110vh;
    background-color: red;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    color: #333333;
    padding: 15px;
    margin-top: 10px;
}

.profileImage {
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    color: #333333;
    padding: 15px;
    width: 300px;
    /* Increased width for more space */
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.profileCard:hover {
    transform: translateY(-5px);
}

.profileImage img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.profileInfo {
    margin-top: 1px;
}

.name {
    font-size: 18px;
    font-weight: bold;
}

.email {
    color: #666;
}

.bio {
    margin-top: 1px;
}

.skill-bubbles {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin: 10px;
    padding: 10px;

}



.rating-stars {
    margin-top: 10px;
}

/* Define colors for filled and unfilled stars */
.star-icon {
    color: #ccc;
    /* Default color for unfilled stars */
}

.star-icon.filled {
    color: #f39c12;
    /* Color for filled stars */
}

.discriptionText {
    width: 80%;
    height: 300px;
    /* margin: 10%; */
    margin-left: 10%;
    display: flex;
    align-self: center;
    flex-direction: column;
    /* To stack the elements vertically */
    justify-content: center;
    /* To vertically center the content */
    text-align: left;
    padding: 10px;
}

.skillscontainer {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 280px;
    margin-left: 12%;
    margin-top: 1%;
}

.Skills {
    width: 100%;
    height: 190px;
}


.buttons {
    width: 150px;
    height: 25px;
    margin: 1%;
    background: black;
    /* Gradient background */
    color: hsl(48, 26%, 93%);
    /* Text color */
    padding: 10px 20px;
    /* Padding for the button */
    border: none;
    /* Remove the button border */
    border-radius: 25px;
    cursor: pointer;
    /* Cursor style on hover */
    display: flex;
    justify-content: center;
    align-items: center;
    /* Vertically center text */
    text-align: center;
    /* Center text horizontally */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
}

.buttons:hover {
    background: linear-gradient(45deg, hsl(48, 26%, 93%));
    /* Darker gradient on hover */
    transform: scale(1.05);
    /* Slightly scale up on hover */
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
    /* Enhanced shadow on hover */
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10%;
    margin-bottom: 0%;
}

.profile-screen-container {
    font-family: 'League Spartan';
    /* Replace 'Your-Desired-Font' with your preferred font */
}

.jobContainer {
    display: flex;
    flex-direction: row;

}