datepicker-styles.css

/* Style the date picker */
.react-datepicker {
  font-family: 'League Spartan';
   /* Make the date picker full width */
  
}

/* Style the input field within the date picker */
.react-datepicker__input-container input {
  font-family: 'League Spartan';
  margin-bottom: 10px;
  margin-top: 10px;
  width: 95%;
  height: 24px;
  padding: 12px; /* Adjust padding as needed */
  border: 1px solid #ccc; /* Add border styles as needed */
  border-radius: 4px; /* Add border radius as needed */
}

/* Style the date picker calendar */
.react-datepicker__calendar {
  font-family: 'League Spartan';
}

/* Style the date picker time container */
.react-datepicker__time-container {
  font-family: 'League Spartan';
}

/* Style the time picker */
.react-datepicker__time-list {
  font-family: 'League Spartan';
}

/* Style the time picker option */
.react-datepicker__time-list-item {
  font-family: 'League Spartan';
  padding: 8px; /* Adjust padding as needed */
}

/* Style the submit button */
.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

}

.button-container button {
  font-family: 'League Spartan';
  padding: 12px 24px; /* Adjust padding as needed */
  background-color: #007bff; /* Change to your desired background color */
  color: #fff; /* Change to your desired text color */
  border: none;
  border-radius: 4px; /* Add border radius as needed */
  cursor: pointer;

  transition: background-color 0.3s;
}

.button-container button:hover {
  background-color: #0056b3; /* Change to your desired hover color */
}
