.centered-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
}

.development-text {
    font-size: 36px;
    /* Increased font size to 36px */
    font-weight: bold;
    /* Brighter color, e.g., orange */
    margin-bottom: 10px;
}

.subtext {
    font-size: 20px;
    /* Increased font size to 20px */
    color: #777;
}

/* Card.css */
.card {
    border: 1px solid #ddd;
    border-radius: 8px;
    height: 100px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    max-width: 300px;
    margin: 16px;

}

.card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.card h2 {
    font-size: 1.5rem;
    margin: 0;
}

.card p {
    margin: 0;
}

/* Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    background-color: hsl(48, 26%, 93%);
    color: #fff;
}

.headerFonts {}

.logo {
    margin-left: 50px;
}

.nav ul {
    list-style: none;
    display: flex;
}

.nav li {
    margin-right: 20px;
}

.nav a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
}

/* Button.css */
.custom-button {
    background-color: black;
    /* Background color */
    color: hsl(48, 26%, 93%);
    /* Text color */
    border: none;
    /* Remove button border */
    padding: 20px 20px;
    /* Add padding for a comfortable button size */
    cursor: pointer;
    /* Show pointer cursor on hover */
    border-radius: 5px;
    /* Rounded corners */
    font-size: 16px;
    /* Text size */
    transition: background-color 0.3s ease;
    /* Smooth color transition on hover */

    /* Add more styles as needed */
}

.custom-button:hover {
    background-color: black;
    /* Change background color on hover */
}

/* filterCard  */
/* Styles for FilterCard */
.card {
    background-color: hsl(48, 26%, 93%);
    border: hsl(48, 26%, 93%);
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    padding: 20px;
}

.card-content {
    display: flex;
    flex-direction: column;
}

.card-content h3 {
    margin-bottom: 10px;
}

.card-content ul {
    padding: 0;
    list-style: none;
    margin: 0;
}

.card-content li {
    margin-bottom: 8px;
}

.card-content input {
    margin-right: 10px;
}

/* ProfileCard.css */

.profile-card {
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    color: #333333;
    padding: 25px;
    font-family: 'League Spartan';
    width: 300px;
    /* Increased width for more space */
    margin: 20px;
    font-size: 17px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.profile-card img {
    width: 120px;
    /* Adjusted image size to make it larger */
    height: 120px;
    /* Adjusted image size to maintain aspect ratio */
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
    /* Increased margin for spacing */
}


.profile-card h2 {
    font-size: 1.3em;
    /* Adjusted font size */
    margin: 0;
    margin-bottom: 5px;
}

.profile-card p {
    margin: 0;
    font-size: 1em;
    /* Adjusted font size */
    color: #777777;
}

.profile-card-content {
    margin-top: 10px;
    /* Adjusted margin */
}

.profile-card-content p {
    margin: 5px 0;
}

.profile-card-content p strong {
    font-weight: bold;
}

.profile-card-content a {
    color: #3498db;
    text-decoration: none;
}

.profile-card-content a:hover {
    text-decoration: underline;
}

.profile-card:hover {
    background-color: #f5f5f5;
    /* Slightly elevate the card */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    /* Add a subtle shadow on hover */
}

/* Define colors for filled and unfilled stars */
.star-icon {
    color: #ccc;
    /* Default color for unfilled stars */
}

.star-icon.filled {
    color: #f39c12;
    /* Color for filled stars */
}

.rating-section {
    margin: 10px;
}

.skill-bubbles {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 2px;
}

.skill-bubble {
    padding: 8px 12px;
    background-color: #f0f0f0;
    color: #333;
    border-radius: 20px;
    font-size: 14px;
    margin: 1px;
}






/* FlatList.css */

.flat-list {
    display: flex;
    flex-wrap: wrap;
}

.profile-card {
    border: 1px solid #ccc;
    margin: 10px;
    width: 45%;
    /* Adjust the width for the two columns */
    box-sizing: border-box;
}

.profile-card-header {
    display: flex;
    align-items: center;
    padding: 10px;
}

.profile-card-header img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.profile-card-content {
    padding: 10px;
}

.image-item {
    padding: 20px 0 20px 20px;
}

/* Add this CSS to your stylesheet */
/* Add this CSS to your stylesheet */

.carousel-container {
    position: relative;
    overflow: hidden;
    /* Hide the horizontal scrollbar */
}

.horizontal-card-list {
    display: flex;
    gap: 30px;
    scroll-behavior: smooth;
    margin-bottom: -17px;
    /* Compensate for the scrollbar height */
}

.card {
    flex: 0 0 300px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s;
}

.card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.nav-button {
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 24px;
    padding: 15px 15px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s;
}

.left {
    left: 0;
}

.right {
    right: 0;
}

.nav-button:hover {
    background: rgba(0, 0, 0, 0.7);
}